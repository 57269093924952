<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li>{{ values }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <b-card-body>
        <b-row class="my-2">
          <!-- Right: value Details -->
          <b-col cols="12" md="12">
            <!-- value Name -->
            <div class="text-center">
              <span
                v-for="(translation, index) in valueData.translation"
                style="font-size: 25px; margin: auto"
                :key="index"
              >
                {{ translation.name }}
                <span
                  v-if="
                    valueData.translation.length > 1 &&
                    index < valueData.translation.length - 1
                  "
                  >-</span
                >
              </span>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <!-- Value Translations -->
      <b-card-body>
        <b-tabs align="center" class="text-center">
          <b-tab
            v-for="(translation, index) in valueData.translation"
            :title="translation.locale == 'en' ? 'English' : 'Arabic'"
            :key="index"
          >
            <div>
              <b-card-text v-html="translation.name"></b-card-text>
            </div>
            <div>
              <b-button
                v-if="
                  authPermissions.includes('delete') &&
                  translation.locale != 'en' &&
                  translation.locale != 'ar'
                "
                v-b-popover.hover.top="'Delete Translation'"
                class="mt-1"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                type="submit"
                @click="deletevalue(translation.id)"
              >
                Delete
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
        <b-button
          v-if="authPermissions.includes('create')"
          block
          class="mt-2"
          variant="success"
          size="md"
          :to="{ path: '/Values/Translation/Add/' + id }"
          >Add translation</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      authPermissions: [],
      id: 0,
      valueData: {},
      errors_back: '',
      showDismissibleAlert: false,
      values: [],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // fetch data of value
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('values/' + this.id)
        .then((result) => {
          this.valueData = result.data.data
        })
        .catch((err) => {
          this.showDismissibleAlert = true
          this.errors_back = err.response.data.data
        })
    },
    // delete translation function
    deletevalue(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('values/translations/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
