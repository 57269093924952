var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-ecommerce-details"},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(values))])])}),0)]),_c('b-card',[_c('b-card-body',[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-center"},_vm._l((_vm.valueData.translation),function(translation,index){return _c('span',{key:index,staticStyle:{"font-size":"25px","margin":"auto"}},[_vm._v(" "+_vm._s(translation.name)+" "),(
                  _vm.valueData.translation.length > 1 &&
                  index < _vm.valueData.translation.length - 1
                )?_c('span',[_vm._v("-")]):_vm._e()])}),0)])],1)],1)],1),_c('b-card',[_c('b-card-body',[_c('b-tabs',{staticClass:"text-center",attrs:{"align":"center"}},_vm._l((_vm.valueData.translation),function(translation,index){return _c('b-tab',{key:index,attrs:{"title":translation.locale == 'en' ? 'English' : 'Arabic'}},[_c('div',[_c('b-card-text',{domProps:{"innerHTML":_vm._s(translation.name)}})],1),_c('div',[(
                _vm.authPermissions.includes('delete') &&
                translation.locale != 'en' &&
                translation.locale != 'ar'
              )?_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Delete Translation'),expression:"'Delete Translation'",modifiers:{"hover":true,"top":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"size":"sm","variant":"danger","type":"submit"},on:{"click":function($event){return _vm.deletevalue(translation.id)}}},[_vm._v(" Delete ")]):_vm._e()],1)])}),1),(_vm.authPermissions.includes('create'))?_c('b-button',{staticClass:"mt-2",attrs:{"block":"","variant":"success","size":"md","to":{ path: '/Values/Translation/Add/' + _vm.id }}},[_vm._v("Add translation")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }